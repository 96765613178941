import * as React from "react"
import { FaLinkedinIn } from 'react-icons/fa';
import { FooterStyled } from "./styles/footer.styled"

const currentYear = new Date().getFullYear();

export default function footer() {
  return (
    <FooterStyled>
     <h1>&copy; {currentYear} Vegard Vonheim</h1>
    <a href="linkedin.com/test"><FaLinkedinIn /></a>
    </FooterStyled>
  )
}

