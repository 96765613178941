import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Header from "../components/header"
import Layout from "../components/layout"
import { Vegard } from "../components/mainImage"
import Seo from "../components/seo"
import { ThemeProvider } from "styled-components"
import GlobalStyles from "../components/styles/global"
import Footer from "../components/footer"

const theme = {
  colors: {
    color: "#ffffff",
    header: "#ebfbff",
    backgroundColor: "#FF33A1",
    footer: "#003333",
  },
  mobile: "768px",
};

const IndexPage = () => (
  <ThemeProvider theme={theme}>
    <>
    <GlobalStyles />
      <Seo title="Hjem" />
      <Header/>
      <Vegard/>
      <StaticImage
        src="../images/gatsby-astronaut.png"
        width={300}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="A Gatsby astronaut"
        style={{ marginBottom: `1.45rem` }}
      />
      <p>
        <Link to="/page-2/">Go to page 2 Bare noe dummytekst en til 
        </Link> <br />
        <Link to="/using-typescript/">Go to "Using TypeScript"</Link> <br />
        <Link to="/using-ssr">Go to "Using SSR"</Link> <br />
        <Link to="/using-dsg">Go to "Using DSG"</Link>
      </p>
      <Footer/>
      </>
  </ThemeProvider>
)

export default IndexPage
